import { config, library, dom } from '@fortawesome/fontawesome-svg-core'

import {
  faEnvelope, faCheck, faExclamation
} from '@fortawesome/free-solid-svg-icons'

library.add(
  faEnvelope, faCheck, faExclamation
)

config.mutateApproach = 'sync'
dom.watch()
